@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700|Poppins:400,700");

$basecolor: #797979;
$secondarycolor: #1a1a5d;

$success: #50ceff;
$warning: #ffb000;
$green: #8cc63f;
$purple: #662d89;
$montserrat: "Montserrat", sans-serif;

// Colors

$brand-quirk: $basecolor;
$brand-default: $secondarycolor;
$brand-primary: $basecolor;
$brand-success: $basecolor; //Also font awesome icons
$brand-warning: #e6ad5c;

$border-color-base: lighten($brand-quirk, 70%);

$bg-color-base: #d8dce3;
$body-bg: $bg-color-base;

$color-gray: #545b68;
$color-green: #409a32;

// Alerts

$alert-success-bg: #9ed566;
$alert-success-border: transparent;
$alert-success-text: darken($alert-success-bg, 35%);

$alert-info-bg: #56c2e8;
$alert-info-border: transparent;
$alert-info-text: darken($alert-info-bg, 40%);

$alert-warning-bg: #fdcc51;
$alert-warning-border: transparent;
$alert-warning-text: darken($alert-warning-bg, 40%);

$alert-danger-bg: #e55869;
$alert-danger-border: transparent;
$alert-danger-text: #fff; //darken($alert-danger-bg, 25%);

// Badge

$badge-font-weight: normal;
$input-border: lighten($brand-quirk, 60%);
// Buttons
$text-color: black;
$btn-default-color: $text-color;
$btn-default-bg: #fff;
$btn-default-border: $input-border;

$btn-primary-border: transparent;
$btn-success-border: transparent;
$btn-warning-border: transparent;
$btn-danger-border: transparent;
$btn-info-border: transparent;

// Forms

$input-color: $text-color;
$input-bg: lighten($body-bg, 12%);
$input-bg-disabled: lighten($body-bg, 5%);

$input-height-base: 38px;
$input-height-large: 42px;
$input-height-small: 32px;

$input-color-placeholder: lighten($text-color, 40%);
$border-radius-base: 2px;
$input-border-radius-large: $border-radius-base;
$input-border-radius-small: $border-radius-base;

$input-group-addon-bg: lighten($body-bg, 10%);

$ckbox-size: 14px;
$rdiobox-size: 14px;
$ckbox-border-color: $border-color-base;
$ckbox-color: #fff;

$rdio-border-color: $text-color;

// Form States

// Grid

$grid-gutter-width: 20px;

// Header

$header-color: lighten($brand-quirk, 50%);
$header-bg: $brand-quirk;
$header-text: #ececec;
$header-height: 64px;

$logopanel-bg: darken($header-bg, 3%);

// Left Panel

$leftpanel-bg: #fff;
$leftpanel-border: #e4e4e4;
$leftpanel-hover: lighten($body-bg, 10%);
$leftpanel-width: 220px;
$leftpanel-a: #333438;

// Media Queries Breakpoints

$screen-lg-min: 1650px; // macbook air 11 inches

// Navs

$nav-link-padding: 14px 25px;
$nav-line-border-width: 1px;

$nav-tabs-bg: lighten($brand-quirk, 50%);
$nav-tabs-bg-hover: lighten($brand-quirk, 45%);
$nav-tabs-active-color: lighten($brand-quirk, 25%);

// Pagination

$pagination-color: lighten($brand-quirk, 20%);
$pagination-border: transparent;
$pagination-bg: $bg-color-base;

$pagination-hover-border: transparent;
$pagination-hover-bg: darken($bg-color-base, 10%);

$pagination-active-bg: $brand-success;
$pagination-active-border: transparent;

$pagination-disabled-color: lighten($text-color, 30%);
$pagination-disabled-border: transparent;
$pagination-disabled-bg: lighten($bg-color-base, 10%);

$pager-border-radius: $border-radius-base;
$pager-disabled-color: lighten($brand-quirk, 50%);

// Panels

// Popover

$popover-title-bg: #fff;

// Progress Bars

$progress-bg: lighten($bg-color-base, 5%);

// Scaffolding

$link-color: darken($text-color, 20%);
$link-hover-color: lighten($brand-quirk, 20%);
$link-hover-decoration: none;

$transition-base: all 0.2s ease-out 0s;

$border-radius-large: 2px;
$border-radius-small: 2px;

$padding-base-horizontal: 12px;
$padding-base-vertical: 10px;

$padding-large-vertical: 11px;
$padding-large-horizontal: 14px;

$padding-small-vertical: 5px;
$padding-small-horizontal: 8px;

$padding-xs-vertical: 3px;
$padding-xs-horizontal: 5px;

// Table

$table-bg: lighten($bg-color-base, 8%);
$table-bg-hover: lighten($bg-color-base, 5%);
$table-bg-accent: lighten($bg-color-base, 12%);

$table-cell-padding: 10px 15px;
$table-border-color: #fff;

// Typography

$headings-color: $brand-quirk;
$headings-font-weight: bold;

$font-family-opensans: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
$font-family-raleway: "Raleway", "Helvetica Neue", Helvetica, sans-serif;

$font-family-primary: $font-family-opensans;

$text-color: #696c74;
$font-size-base: 12px;
$font-size-large: 16px;
$text-muted: lighten($text-color, 25%);

// Wells

$well-bg: $bg-color-base;

$screen-md-min: 900;
$screen-xs-max: 500;

body {
  background: #ebedef;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  margin-top: 0;
}

[rv-cloak] {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.modal-header .close {
  font-size: 60px;
  margin-top: -20px;
  outline: none;

  span {
    outline: none;
  }
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #50858c;
  background: rgba(37, 157, 171, 0.07);
  padding: 7px;
  position: relative;
  padding-left: 40px;
}

.help-block:before {
  content: "\f05a";
  font-family: "normal normal normal 14px/1 FontAwesome";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 13px;
  font-size: 20px;
  top: 5px;
}

// Sidebar
#sidebar {
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 150ms linear;
  transform: translateX(320px);
  color: #3e3d3b;
  height: 100%;
  width: 320px;
  z-index: 99;
  background: whitesmoke;
  box-shadow: 1px 0 1px 1px #4c4c4c;
  &.open {
    transform: translateX(0px);

    .toggleTripCart {
      left: 0;
    }
  }

  .main-item {
    padding: 10px;
    background: white;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .product-pic {
    background-color: #fafafa;
    border-radius: 50%;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    overflow: hidden;
    width: 80px;
    height: 80px;
    float: left;

    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .product-info {
    padding-left: 90px;

    h3 {
      font-size: 13px;
      margin: 0;
    }
  }

  .viewcats {
    background: #edf1f5;
    button {
      padding: 10px;
      display: block;
      float: left;
      border-bottom: 2px solid #8dc3f6;
      font-size: 20px;
      width: 33.333%;
      > img {
        width: 30px;
        height: auto;
      }
    }
  }
  header {
    padding: 15px;
    padding: 20px 15px;
    font-size: 21px;
  }
  > header {
    background-color: $secondarycolor;
    border-bottom: solid 10px $basecolor;
    padding: 17px 11px 15px 70px;
    position: relative;

    color: white;
    font-size: 20px;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .toggleTripCart {
    background: $basecolor;
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: 50px;
    font-size: 28px;
    border: none;
    left: -50px;
    &:focus {
      outline: 0;
    }
  }
  section.main {
    overflow-y: hidden;
    padding-bottom: 69px;
    position: relative;
    height: 100%;
    .bottom {
      border-top: solid 1px #ccc;
      background: white;
      padding: 15px;
      bottom: 0;
      width: 100%;
      .bottomBtn {
        padding: 10px 12px;
        display: block;
        font-size: 16px;
      }
    }
    #mainList {
      height: calc(100% - 125px);
      overflow-y: scroll;
    }
    .layer {
      transition: all 0.3s ease-in-out;
      width: 100%;
      box-shadow: inset 0 -1px 1px #4c5b5c;
      height: 0;
      overflow: hidden;
      text-align: center;
      width: 100%;
      position: absolute;
      background: whitesmoke;
      bottom: 0;
      &.active {
        height: 100%;
        overflow: auto;
        bottom: 0;
        z-index: 2;
      }
      header {
        h3 {
          padding: 0;
          margin: 0;
          margin-bottom: 30px;
        }
      }
      .close {
        color: #333;
        opacity: 1;
        font-size: 33px;
        line-height: 11px;
      }
      .panel-body {
        padding-top: 0;
      }
    }
  }
}

.anonymousNavbar {
  position: absolute;
  padding: 10px;
  border: none;
}

#shapez {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  fill: rgba(0, 0, 0, 0.1);
}

.mainContent {
  margin-top: 0 !important;
}

.correct {
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #8bc34a;
}

.spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10002;

  i {
    color: #fff;
    text-align: center;
    width: 100%;
    margin-top: 20%;
    font-size: 60px;
  }
}

.companyContainer {
  background: rgb(145 145 145);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  color: #fff;
  cursor: pointer;

  .companyLogo {
    background-position: center;
    background-size: 90%;
    background-repeat: no-repeat;
    height: 125px;
    background-color: white;
  }
}

#frontpage {
  .category {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .categoryImage {
    height: 185px;
    background-size: cover;
    background-position: center;
  }

  .yellow {
    color: $warning;
  }

  .green {
    color: $green;
  }

  .purple {
    color: $purple;
  }

  .productName {
    background: rgba(0, 0, 0, 0.05);
    font-weight: bold;
  }

  .productContainer {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  #readSerivceFull {
    color: $basecolor;
    cursor: pointer;
  }

  section {
    padding: 8%;
    text-align: center;
    position: relative;
    background: #fff;

    .curve {
      position: absolute;
      left: 0;
      width: 100%;
      top: -31px;

      @media (max-width: 540px) {
        top: -15px;
      }

      .cls-1 {
        fill: white;
      }
    }

    &.gray {
      background: #f7f7f7;

      .cls-1 {
        fill: #f7f7f7;
      }
    }

    .title {
      font-size: 45px !important;
      padding-bottom: 40px;
      margin: auto;
      max-width: 500px;
      position: relative;

      @media (max-width: 768px) {
        font-size: 25px !important;
      }
    }

    .content {
      padding-top: 20px;
      font-size: 15px;
      margin: auto;
      max-width: 700px;
      text-align: justify;
      text-align-last: center;
      position: relative;
    }
  }

  .btn-transparent {
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
    text-transform: uppercase;
  }

  #featured {
    height: 82vh;
    text-align: center;
    margin: auto;
    background-size: cover;
    background-position: center;

    .featuredContent {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      max-width: 80%;
      margin: auto;
      color: #fff;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);

      #title {
        font-size: 100px;
        font-weight: bold;
        color: #fff;
        font-family: "Allura", cursive;

        @media (max-width: 768px) {
          font-size: 30px;
        }
      }

      #subTitle {
        font-size: 18px;
        width: 100%;
        margin: auto;
        font-weight: bold;

        @media (max-width: 768px) {
          font-size: 26px;
          width: 100%;
        }
      }
    }
  }

  #products {
    hr {
      border-color: #8c8c8c;
    }

    .product {
      background: #fefefe;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      margin-bottom: 20px;
      border-radius: 5px;
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        width: 100%;
        position: absolute;
        display: block;
        bottom: 0;
        height: 33px;
        background: linear-gradient(
          to top,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      img {
        max-width: 60%;
        padding: 20px;
      }

      .productTitle {
        font-weight: bold;
        font-size: 16px;
        color: $basecolor;
        width: 80%;
        margin: auto;
      }

      .productDesc {
        text-align: center;
        width: 70%;
        margin: auto;
        font-size: 11px;
      }
    }
  }

  #medferdir {
    background-size: cover;
    background-position: top;

    .title,
    .content {
      position: relative;
    }

    .panel {
      cursor: pointer;
    }
  }
}

/* Header Panel */

.headerpanel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  background-color: $header-bg;
  z-index: 2000;

  .logopanel {
    position: absolute;
    left: -$leftpanel-width;
    width: $leftpanel-width;
    height: $header-height;
    padding: 18px 15px;
    background-color: $logopanel-bg;

    h2 {
      margin: 0;
      font-weight: 500;

      > a {
        transition: $transition-base;
        &:hover {
          color: lighten($brand-success, 10%);
        }
      }
    }

    @media (min-width: $screen-md-min) {
      left: 0 !important;
    }
  }
}

// .navbar-toggle {
//   display: none;
// }

.blokk {
  display: block;
}

.headerbar {
  position: relative;
  padding-left: $header-height;

  @media (min-width: $screen-md-min) {
    margin-left: $leftpanel-width;
    left: 0 !important;
  }
}

.menutoggle {
  position: absolute;
  width: $header-height;
  height: $header-height;
  top: 0;
  left: 0;
  font-size: 21px;
  cursor: pointer;
  color: $header-text;
  transition: $transition-base;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -11px;
    margin-left: -10px;
  }

  &:hover,
  &:focus {
    color: $header-color;
  }
}

.header-right {
  float: right;
  padding-right: 20px;
}

.headermenu {
  list-style: none;
  margin: 0;
  padding: 0;

  .dropdown-toggle:hover,
  .dropdown-toggle:focus,
  .dropdown-toggle:active,
  .dropdown-toggle.active,
  .open .dropdown-toggle.dropdown-toggle {
    background-color: lighten($header-bg, 3%);
  }

  > li {
    display: inline-block;
    float: left;
    position: relative;

    .btn {
      border-radius: 0;
      background-color: transparent;
      color: $header-text;
      height: $header-height;
      border: 0;
      padding-left: 5px;
      padding-right: 5px;

      &:hover,
      &:focus {
        color: $header-color;
      }

      &:active {
        box-shadow: none;
      }
    }

    .alert-notice {
      .fa {
        position: relative;
        &:after {
          position: absolute;
          content: "";
          top: -2px;
          right: -2px;
          width: 10px;
          height: 10px;
          background-color: #ff564e;
          border-radius: 50px;
          opacity: 0.75;
        }
      }
    }

    > .btn-group {
      margin-bottom: 0;

      > .btn-notice {
        font-size: 21px;

        + .dropdown-menu {
          min-width: 350px;
        }
      }

      > .btn-logged {
        padding: 5px 12px 3px;
        border: 0;

        img {
          vertical-align: middle;
          margin-right: 5px;
          width: 20px;
          border-radius: 50px;
        }

        .caret {
          margin-left: 5px;
          margin-top: -2px;
        }
      }
    }

    .btn-chat {
      font-size: 21px;
    }
  }
}

.today-day {
  margin: 0;
  font-weight: 400;
  color: lighten($brand-quirk, 10%);
}

.today-date {
  margin-top: 8px;
  color: lighten($brand-quirk, 25%);
  font-weight: 500;
}

.today-weather {
  margin: 20px 0 5px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: $font-family-primary;
  color: $brand-quirk;

  + p {
    font-size: 11px;
  }

  > i {
    color: lighten($brand-quirk, 30%);
    margin-right: 5px;
  }
}

.anonymousNavbar {
  border: none;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: rgba(0, 0, 0, 0.6) !important;
  border-radius: 0;
  z-index: 3;

  .btn-clear {
    background: none;
    border: none;
    color: #fff;
    font-weight: bold;
  }

  .ml-15 {
    margin-left: 10px;
  }

  #signup {
    color: #fff;
    background: #ffae00;
    border: 1px solid #ffae00;
  }

  #login {
    border: 1px solid #fff;
    background: none;
    color: #fff;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .mainLogo {
    padding: 0px;
    float: left;

    img {
      width: 150px;
    }
  }

  #anonymousHeaderLinks {
    padding: 3px;
    padding-right: 0;
    @media (max-width: 768px) {
      padding: 0;
      margin: 0px;
      width: 100vw;
      position: absolute;
      left: 0px;
      top: 64px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
      display: none;

      &.open {
        display: block;
      }
    }

    ul {
      @media (max-width: 768px) {
        margin: 0;
        display: flex;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.59);

        div {
          padding: 20px;
        }
      }
      li {
        div {
          padding: 7px 15px;
          margin-right: 11px;
          border-radius: 2px;

          @media (max-width: 768px) {
            margin: 0;
            padding: 15px;
            border-radius: 0;
            border: none !important;
            background: #6f6f6f !important;
            color: #ffffff !important;
            border-bottom: 1px solid #565656 !important;

            // &:hover{
            //     background-color: inherit;
            // }
          }
        }
      }
    }
  }
}

#treatmentContainer,
#productContainer {
  display: flex;
  gap: 20px;
  margin: auto;
  justify-content: center;
  max-width: 1200px;
  flex-wrap: wrap;

  .category,
  .companyContainer {
    max-width: 350px;
    width: 100%;
  }
}

.footerContainer {
  padding: 50px;
}

.footerContainer ul {
  list-style-type: none;
}

.footerContainer ul li {
  margin-bottom: 10px;
}

.footerContainer a {
  color: #fff;
}

.footerLogo {
  position: absolute;
  top: -4px;
}

.footerLogo img {
  max-width: 100%;
}

.creditCards {
  width: 100%;
  position: absolute;
  top: 9px;
}

@media (max-width: 768px) {
  .creditCards {
    position: relative;
    top: 0;
  }

  .footerContainer {
    text-align: center;
  }
  .footerContainer ul {
    padding-left: 0;
  }

  .footerContainer ul li {
    margin-bottom: 20px;
  }

  .footerContainer ul li {
    margin-bottom: 20px;
  }

  .footerLogo img {
    width: 200px;
  }

  .footerLogo {
    margin-bottom: 25px;
    position: relative;
  }
}

html,
body {
  background-color: #fff;
}

.text {
  font-family: "Myriad pro", sans-serif;
}

.mainContent {
  margin-top: 64px;
}

.mainLogo {
  padding: 10px;
  float: left;

  img {
    width: 175px;
  }
}

.footerBot {
  background: #191919;
  padding: 20px;
  color: #b5b5b5;

  .item {
    padding: 0 15px;
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;

    .item {
      padding: 10px;
    }
  }
}

.navContainer,
.footerContainer {
  margin: auto;
}

.headerLinks {
  padding: 23px;
  padding-right: 0;

  .link {
    float: left;
    font-weight: bold;
    margin-left: 15px;
  }

  .innskra {
    background: #ffffff;
    padding: 5px 10px;
    margin-top: -6px;
    color: #299cf3;
    border: 1px solid;
    box-sizing: border-box;
    border-radius: 2px;
  }

  .stofna {
    background: #299cf3;
    padding: 5px 10px;
    margin-top: -6px;
    color: white;
    border: 1px solid #299cf3;
    border-radius: 2px;
  }
}

.footerContainer {
  background-color: #3a3a3a;
  color: #fff;
}

.productParent {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
  justify-content: center;

  .product {
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.22);
    border-radius: 3px;
    overflow: hidden;

    .productImage {
      padding-bottom: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .productName {
      padding: 12px;
      background: #e1e1e1;
    }
  }
}

.staffContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
  gap: 20px;
  justify-content: center;

  .staffMember {
    .staffImage {
      padding-bottom: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .staffName {
      padding: 12px;
      padding: 12px;
      font-weight: bold;
      font-size: 18px;
    }

    .staffDescription {
    }
  }
}
